const AnimatedSpaceChessBackground = () => (
    <div className="fixed inset-0 z-0 overflow-hidden bg-black">
        <div className="absolute inset-0 bg-[url('https://aqwlqhpjpjlhkbfxhyzt.supabase.co/storage/v1/object/public/assets/space-bg.jpg')] bg-cover bg-center opacity-50"></div>
        <svg className="absolute w-full h-full" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <radialGradient id="stars" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                    <stop offset="0%" stopColor="white" stopOpacity="1" />
                    <stop offset="100%" stopColor="white" stopOpacity="0" />
                </radialGradient>
            </defs>
            <g className="stars">
                {[...Array(100)].map((_, i) => (
                    <circle
                        key={i}
                        cx={Math.random() * 100 + '%'}
                        cy={Math.random() * 100 + '%'}
                        r="1"
                        fill="url(#stars)"
                        className="star"
                        style={{
                            animation: `twinkle 4s infinite ${Math.random() * 4}s`,
                        }}
                    />
                ))}
            </g>
        </svg>
        <style>{`
        @keyframes twinkle {
          0%, 100% { opacity: 0.5; transform: scale(1); }
          50% { opacity: 1; transform: scale(1.5); }
        }
        .star {
          will-change: transform, opacity;
        }
      `}</style>
    </div>
)
export default AnimatedSpaceChessBackground;