import { Button } from '@mui/material'
import { motion } from 'framer-motion'
import { ChevronRight } from 'lucide-react'

function Home() {
    return (
        <section className="container mx-auto px-4 py-20 text-center">
            <motion.h1
                className="text-5xl md:text-7xl font-bold mb-6 text-white"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
            >
                AI Powered Software Solutions
            </motion.h1>
            <motion.p
                className="text-xl md:text-2xl text-blue-200 mb-8 max-w-3xl mx-auto"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
            >
                We develop customized applications with artificial intelligence and mobile hybrid technologies. We design the future today and optimize your business processes.
            </motion.p>
            <motion.div
                className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}>
                <Button href='#contact' size="large" variant="outlined" className="w-full sm:w-auto border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-white">
                    Contact Us <ChevronRight className="ml-2 h-4 w-4" />
                </Button>
            </motion.div>
        </section>
    )
}

export default Home