import { useState, useEffect } from 'react'
import AnimatedSpaceChessBackground from './components/AnimatedSpaceChessBackground';
import Header from './sections/Header';
import Features from './sections/Features';
import Products from './sections/Products';
import References from './sections/References';
import Footer from './sections/Footer';
import Home from './sections/Hero';
import Contact from './sections/Contact';

export default function App() {
  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(true), [])

  if (!mounted) return null

  return (
    <div className="min-h-screen bg-black text-white">
      <AnimatedSpaceChessBackground />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/70 to-black z-0"></div>
      <Header />

      <main className="relative z-10">
        <Home />
        <Features />
        <Products />
        <References />
        <Contact />
      </main >
      <Footer />

    </div >
  )
}