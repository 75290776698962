import { motion } from 'framer-motion'
import { Mail, MapPinCheck } from 'lucide-react'
import { fadeIn, stagger } from '../constants'


const contactFeatures = [
    { icon: Mail, title: "Email", description: "info@deeproai.com" },
    { icon: MapPinCheck, title: "Address", description: "Gebze, Kocaeli / Eskişehir" }
]

function Contact() {
    return (
        <motion.section
            id='contact'
            className="py-20"
            variants={stagger}
            initial="initial"
            animate="animate">
            <div className="container mx-auto px-4">
                <motion.h2
                    className="section-header"
                    {...fadeIn}
                >              Contact Us
                </motion.h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {contactFeatures.map((item, index) => (
                        <motion.div
                            key={index}
                            className="bg-gray-900/50 backdrop-blur-md p-6 rounded-lg shadow-lg hover:shadow-blue-500/20 transition-all duration-300 transform hover:-translate-y-2 flex flex-row items-center"
                            {...fadeIn}
                        >
                            <item.icon className="h-8 w-8 text-blue-400 mb-4" />
                            <motion.div className="ml-4">
                                <h3 className="text-xl font-semibold mb-2 text-white">{item.title}</h3>
                                <p className="text-gray-400">{item.description}</p>
                            </motion.div>
                        </motion.div>
                    ))}

                </div>
            </div >
        </motion.section>
    )
}

export default Contact