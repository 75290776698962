import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Menu } from 'lucide-react'
import Logo from '../assets/main_logo.png'

const navMenuOptions = [
    {
        id: 'features',
        text: 'Features'
    },
    {
        id: 'products',
        text: 'Products'
    },
    {
        id: 'references',
        text: 'References'
    },
    {
        id: 'contact',
        text: 'Contact'
    }
];
function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    return (
        <header className="sticky top-0 z-50 bg-black/10 backdrop-blur-sm " >
            <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                <motion.div
                    className="flex items-center space-x-2"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <img
                        src={Logo}
                        alt="deepro logo"
                        width={120}
                        height={40}
                    />
                </motion.div>
                <nav className="hidden md:flex space-x-8">
                    {navMenuOptions.map((item, index) => (
                        <motion.a
                            key={item.id}
                            href={"#" + item.id}
                            className="relative overflow-hidden group text-white"
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                            <span className="relative z-10">{item.text}</span>
                            <span className="absolute inset-0 bg-blue-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform -skew-x-12"></span>
                        </motion.a>
                    ))}
                </nav>
                <button className="md:hidden text-white" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <Menu className="h-6 w-6" />
                </button>
            </div>
            {isMenuOpen && (
                <motion.div
                    className="md:hidden bg-black p-4"
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <nav className="flex flex-col space-y-4">
                        {navMenuOptions.map((item) => (
                            <a key={item.id} href={"#" + item.id} className="text-white hover:text-blue-400 transition-colors">{item.text}</a>
                        ))}
                    </nav>
                </motion.div>
            )}
        </header>
    )
}

export default Header