
import { LinkedinIcon } from 'lucide-react'

const Footer = () => {
    return (
        <footer className="bg-black/80 backdrop-blur-md py-12 relative z-10">
            <div className="container mx-auto px-4 text-center text-gray-400 flex flex-row justify-between">
                <p>&copy; {new Date().getFullYear()} Deepro AI Yazılım ve Danışmanlık All rights reserved across the multiverse.</p>
                <a href='https://www.linkedin.com/company/deepro-ai-yaz%C4%B1l%C4%B1m-ve-dan%C4%B1%C5%9Fmanl%C4%B1k' target="_blank" rel="noopener noreferrer">
                    <LinkedinIcon />
                </a>
            </div>
        </footer>
    )
}

export default Footer