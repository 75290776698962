import { motion } from 'framer-motion'
import { Cpu, Combine, CloudCog } from 'lucide-react'
import { fadeIn, stagger } from '../constants'

const features = [
    { icon: Combine, title: "Hybrid Application Development", description: "We develop innovative hybrid applications that provide seamless integration between desktop and mobile platforms. We offer customized solutions for you." },
    { icon: Cpu, title: "Artificial Intelligence Integration", description: "We optimize data analytics with artificial intelligence technologies and make your business processes more intelligent. We help you make data-driven decisions." },
    { icon: CloudCog, title: "Cloud Service Integration", description: "We empower your business with advanced cloud infrastructure, providing comprehensive data management and storage solutions. We support your business growth." }
]

function Features() {
    return (
        <motion.section
            id='features'
            className="py-20"
            variants={stagger}
            initial="initial"
            animate="animate"
        >
            <div className="container mx-auto px-4">
                <motion.h2
                    className="section-header"
                    {...fadeIn}
                >              Features
                </motion.h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="bg-gray-900/50 backdrop-blur-md p-6 rounded-lg shadow-lg hover:shadow-blue-500/20 transition-all duration-300 transform hover:-translate-y-2"
                            {...fadeIn}
                        >
                            <feature.icon className="h-12 w-12 text-blue-400 mb-4" />
                            <h3 className="text-xl font-semibold mb-2 text-white">{feature.title}</h3>
                            <p className="text-gray-400">{feature.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div >
        </motion.section >
    )
}

export default Features