import { motion } from 'framer-motion'
import { fadeIn, stagger } from '../constants'
import { ExternalLink } from 'lucide-react'

const references = [
    {
        icon: ExternalLink,
        title: "Wowoo",
        url: "https://wowooapps.com/"
    },
    {

        icon: ExternalLink,
        title: "The Baby Generator",
        url: "https://thebabygenerator.com/tr"
    },
    {

        icon: ExternalLink,
        title: "ICNN akademi",
        url: "https://icnnakademi.com/"
    },
    {

        icon: ExternalLink,
        title: "Deutschland Time",
        url: "https://www.deutschlandtime.com/"
    },
]

function References() {
    return (
        <motion.section
            id='references'
            className="py-20"
            variants={stagger}
            initial="initial"
            animate="animate"
        >
            <div className="container mx-auto px-4">
                <motion.h2
                    className="section-header"
                    {...fadeIn}
                >              References
                </motion.h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {references.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="bg-gray-900/50 backdrop-blur-md p-6 rounded-lg shadow-lg hover:shadow-blue-500/20 transition-all duration-300 transform hover:-translate-y-2 flex flex-row items-center"
                            {...fadeIn}
                        >
                            <feature.icon className="h-6 w-6 text-blue-400 mb-4" />
                            <motion.div className="ml-4">
                                <a target="_blank" rel="noreferrer" className="text-xl font-semibold mb-2" href={feature.url}>{feature.title}</a>
                                <p><a target="_blank" rel="noreferrer" className="text-gray-400" href={feature.url}>{feature.url}</a></p>
                            </motion.div>
                        </motion.div>
                    ))}
                </div>
            </div >
        </motion.section >
    )
}

export default References