import { motion } from 'framer-motion'
import GiyinLogo from '../assets/giyin-logo.png'
import KurumsalGBTLogo from '../assets/kurumsalgbt-logo.png'
import { fadeIn, stagger } from '../constants'

const products = [
    { icon: GiyinLogo, title: "Giyin App", description: "AI wardrobe app" },
    { icon: KurumsalGBTLogo, title: "KurumsalGBT", description: "AI solution for companies" },]

function Products() {
    return (
        <motion.section
            id='products'
            className="py-20"
            variants={stagger}
            initial="initial"
            animate="animate"
        >
            <div className="container mx-auto px-4">
                <motion.h2
                    className="section-header"
                    {...fadeIn}
                >              Products
                </motion.h2>
                <div className="grid md:grid-cols-3 gap-8">
                    {products.map((feature, index) => (
                        <motion.div
                            key={index}
                            className="bg-gray-900/50 backdrop-blur-md p-6 rounded-lg shadow-lg hover:shadow-blue-500/20 transition-all duration-300 transform hover:-translate-y-2"
                            {...fadeIn}
                        >
                            <img src={feature.icon} alt="" className="h-24 w-24 text-blue-400 mb-4" />
                            <h3 className="text-xl font-semibold mb-2 text-white">{feature.title}</h3>
                            <p className="text-gray-400">{feature.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div >
        </motion.section >
    )
}

export default Products